import React from 'react'
import Header from '../../../../components/Section/Header/Header'
import WeekComponent from '../../../../components/Login-section/Psychologist-component/Week-component/Week-component'

const WeekPsycho = () => {
  return (
    <div>
        <Header />
        <WeekComponent />
    </div>
  )
}

export default WeekPsycho