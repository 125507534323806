import React from 'react'
import Header from '../../../../components/Section/Header/Header'
import AgeComponent from '../../../../components/Login-section/Psychologist-component/Age-component/Age-component'

const AgePsycho = () => {
  return (
    <div>
        <Header />
        <AgeComponent />
    </div>
  )
}

export default AgePsycho