import React from 'react'
import ConnectionLogin from '../../../components/Login-section/Connection-login/Connection-login'

const FormLogin = () => {
  return (
    <div>
        <ConnectionLogin />
    </div>
  )
}

export default FormLogin