import React from 'react'
import Header from '../../../components/Section/Header/Header'
import CheckEmailComponent from '../../../components/Login-section/Check-Email-component/Check-Email-component'

const CheckEmailLogin = () => {
  return (
    <div>
        <Header />
        <CheckEmailComponent />
    </div>
  )
}

export default CheckEmailLogin