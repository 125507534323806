// src/AccountBanner.js
import React from 'react';
import './Welcome-Subscription-component.css';

const WelcomeSubscriptionComponent = () => {
    return (
        <div className="abonnement-header">
            <h2>Abonnement</h2>
        </div>
    );
};

export default WelcomeSubscriptionComponent;
